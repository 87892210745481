import './styles.css'

import conheca from './assets/conheca.jfif'
import descubra from './assets/descubra.jfif'
import compartilhe from './assets/compartilhe.jfif'
import { Box, Typography } from '@mui/material'

const Jornada = () => {
    return <section id='section-jornada'>

        <Box sx={{
            display: 'flex', flexDirection: 'column', margin: '5vh 0 7vh 0'
            , '@media(max-width: 1000px)': { margin: '0 35px 3vh 35px' }
        }} >
            <Typography variant='categoryTitle' sx={{ margin: '2vh 0 0.5vh 0' }}>A jornada Badô</Typography>
            <Typography variant='subTitle'>O seu momento é muito mais do que uma encomenda.</Typography>
        </Box>

        <div id='jornada__title--container'>
        </div>



        <div id='jornada__stepsContainer'>
            <div className='jornada__step' id='step_Conheca'>
                <img className='jornada__step--img' src={conheca} />
                <div className='jornada__step--textContainer'>
                    <p className='jornada__step--title'>Conheça</p>
                    <p className='jornada__step--subtitle'>nossa história</p>
                </div>
            </div>
            <div className='jornada__step' id='step_Descubra'>
                <img className='jornada__step--img' src={descubra} />
                <div className='jornada__step--textContainer'>
                    <p className='jornada__step--title'>Descubra</p>
                    <p className='jornada__step--subtitle'>nossos produtos</p>
                </div>
            </div>
            <div className='jornada__step' id='step_Compartilhe'>
                <img className='jornada__step--img' src={compartilhe} />
                <div className='jornada__step--textContainer'>
                    <p className='jornada__step--title'>Compartilhe</p>
                    <p className='jornada__step--subtitle'>sua experiência</p>
                </div>
            </div>
        </div >
    </section >
}

export default Jornada