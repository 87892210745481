import { createTheme } from '@mui/material/styles';
import { typography, MuiTypography } from './typography';
import { myComponents } from './components';

let theme = createTheme({});

theme = createTheme(theme, {
    typography
    , components: {
        MuiTypography,
        ...myComponents,
    }
    , palette: {
        primary: theme.palette.augmentColor({ color: { main: "#B8966E", contrastText: "#ffffff" }, name: 'primary' })
        , secondary: theme.palette.augmentColor({ color: { main: "#fffff", contrastText: "#00000" }, name: 'primary' })
        , aberto: theme.palette.augmentColor({ color: { main: "#EEF0F2", contrastText: "#080f10" }, name: 'aberto' })

    }
});

export default theme