import { getWhatsappUrl, openPageInNewTab } from '../../../../components/redirects';
import './styles.css'
import { Button } from "@mui/material";

const Sticky = () => {
    const goZap = () => {
        openPageInNewTab(getWhatsappUrl(process.env.REACT_APP_BUSINESS_PHONE))
    }

    return <div id='sticky'>
        <Button /* endIcon={} */ variant='contained' onClick={() => goZap()}>Quero fazer meu pedido</Button>
    </div>
}

export default Sticky