import React from 'react';

// Styling and assets
import './styles.css'
import { ReactComponent as LogoBox } from '../../../../components/assets/logobox.svg';
/* import { ReactComponent as SvgLogo } from '../../../../components/assets/logofull.svg';
import { ReactComponent as LogoHeader } from '../../../../components/assets/logo_nobg_nosubtitle.svg'; */
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate } from 'react-router-dom';

const TextButton = props => {
    const { text, link } = props
    const nav = useNavigate()

    return <div className='header__link' onClick={() => nav(link)}>
        {text}
    </div>
}

const IGLink = props => {
    return <div className='header__link' onClick={() => window.open(process.env.REACT_APP_IG_PROFILE, '_blank')}><InstagramIcon /></div>
}

const Logo = () => {
    return <div className='header__logo'>
        <LogoBox id='logo__header' />
    </div>
}

const LeftSideLinks = {
    "Home": < TextButton text='Home' link='/' />
    , "Nossa história": < TextButton text='Nossa história' link='/nossa-historia' />
    , "Produtos": < TextButton text='Produtos' link='/produtos' />
}

const RightSideLinks = {
    "Blog": < TextButton text='Blog' link='/blog' />
    , "Dúvidas frequentes": < TextButton text='Dúvidas frequentes' link='/faq' />
    , "IG": <IGLink />
}

const Header = () => {
    return <section id='section-header'>
        <div className='header__side'>
            {React.Children.toArray(Object.values(LeftSideLinks))}
        </div>

        <Logo />

        <div className='header__side'>
            {React.Children.toArray(Object.values(RightSideLinks))}
        </div>
    </section>
}

export default Header