import './styles.css'

import { ReactComponent as WhatsappLogo } from '../../../../components/assets/whatsapp.svg';
import Instagram from '@mui/icons-material/Instagram';
import { openPageInNewTab, getWhatsappUrl } from '../../../../components/redirects';
import { ReactComponent as SvgLogo } from '../../../../components/assets/logofull.svg';

const Footer = () => {
    return <div className='footerContainer'>
        <SvgLogo id='footer-logo' />
        <div className='footerInfo'>
            <p>Aguardamos seu contato!</p>
            <p>
                <a href='/'>BADÔ - Bolos artesanais e doces</a></p>
            <br />

            <p>E-mail:
                <a href={`mailto:${process.env.REACT_APP_EMAIL}`}> {process.env.REACT_APP_EMAIL}</a>
            </p>

            <p>Celular:
                <a href={`tel:${process.env.REACT_APP_HREF_PHONE}`}>{process.env.REACT_APP_DISPLAY_PHONE}</a>
            </p>

            <br />
            <div className='socialMedia'>
                <Instagram className='footer__svg' onClick={() => openPageInNewTab(process.env.REACT_APP_IG_PROFILE)} />
                <WhatsappLogo className='footer__svg' onClick={() => openPageInNewTab(getWhatsappUrl(process.env.REACT_APP_WHATS_PHONE))} />
            </div>

            <br />

            <p>Curitiba, Paraná, BR</p>

        </div>
    </div>
}

export default Footer