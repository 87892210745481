import './styles.css'

import img_left from './assets/left.jfif'
import img_right from './assets/right.jfif'
import ActionButton from '../../../../components/mix/ActionButton'
import { useNavigate } from 'react-router-dom';

const OurStory = () => {
    const nav = useNavigate()

    return <section id='ourStory'>

        <div id='ourStory__img-left'>
            <img src={img_left} />
        </div>

        <div id='ourStory__text-container'>
            <p id='ourStory__text-text'>​Em cada bolo, celebramos vidas e histórias reais.  </p>
            <ActionButton label='Conheça nossa história' onClick={() => nav('/nossa-historia')}
                sx={{ backgroundColor: 'black', color: 'white' }} />
        </div>

        <div id='ourStory__img-right'>
            <img src={img_right} />
        </div>

    </section>
}

export default OurStory