import React from 'react';
import Jumbotron from './jumbotron/jumbotron';
import './styles.css'

/* É assim que uma image deve ser lazy loaded (Levar para um CDN posteriormente):
<img
    srcSet={`${image.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
    src={`${image.img}?w=164&h=164&fit=crop&auto=format`}
    alt={image.title}
    loading="lazy"
/> */

const Showcase = () => <section className='showcase'> <Jumbotron /> </section>

export default Showcase