import express from './assets/express.jfif' 
import decorado from './assets/decorado.jfif' 
import docinhos from './assets/docinhos.png' 

const categoriesData = [
    {name: 'Linha Express', src: express}
    , {name: 'Bolos decorados', src: decorado}
    , {name: 'Docinhos', src: docinhos}
]

export default categoriesData