import { Button } from '@mui/material'

const ActionButton = (props) => {
    const { label, onClick, sx } = props

    return <Button
        variant='bado'
        onClick={() => onClick}
        sx={{ ...sx }}
    >{label}</Button>
}

export default ActionButton