import './styles.css'

import imageData from './imageData';
import ActionButton from '../../../../../components/mix/ActionButton';
import { useNavigate } from 'react-router-dom';

const Jumbotron = props => {
    const { src, text } = imageData[0]
    const nav = useNavigate()

    return <div id='jumbotron__container'>
        <img id='jumbotron__active--img' src={src} />
        <div id='jumbotron__active--textContainer'>
            <p id='jumbotron__active--text'>{text}</p>
            <ActionButton label='Confira' onClick={() => nav('/')}
                sx={{ backgroundColor: 'var(--on-background)', color: 'var(--primary-text)' }} />
        </div>
    </div>
}

export default Jumbotron