// Section components
import Showcase from './sections/section_showcase/showcase';
import OurStory from './sections/section_ourStory/ourStory';
import ProductCategories from './sections/section_productCategories/productCategories';
import Jornada from './sections/section_jornada/jornada';
import Quote from './sections/section_quote/quote';

const Home = () => {
    return <>
        <Showcase />
        <OurStory />
        <ProductCategories />
        <Jornada />
        <Quote />
    </>
}

export default Home