export const typography = {
    fontFamily: [
        'Nunito'
    ].join(',')
    , categoryTitle: {
        fontFamily: 'Fahkwang',
        fontWeight: 400,
        fontSize: '48px',
        textTransform: 'uppercase',
        margin: '6vh 0 5vh 0',
        lineHeight: '7vh',
        textAlign: 'center',
        width: '100%',
        '@media(max-width:1000px)': {
            fontSize: '32px'
            , margin: '2vh 0'
            , lineHeight: '5vh'
        }
    }
    , text: {
        fontFamily: 'Cormorant Garamond',
        fontWeight: 400,
        fontSize: '28px',
        margin: '25px 0',
        textAlign: 'center',
        width: '100%',
        '@media(max-width:1000px)': {
            fontFamily: 'Fahkwang',
            fontSize: '26px',
            margin: '24px 0'
        }
    }
    , subTitle: {
        fontFamily: 'Cormorant Garamond',
        fontWeight: 400,
        fontSize: '28px',
        margin: '25px 0',
        textAlign: 'center',
        width: '100%',
        '@media(max-width:1000px)': {
            fontSize: '18px'
            , margin: '0'
        }
    }
}

export const MuiTypography = {
    defaultProps: {
        variantMapping: {
            categoryTitle: 'h3'
            , text: 'p'
        },
    },
}