/* 
MUI doesn't allow for customizing variants of some composite elements, like TextField.
A reasonable workaround is to add 'subvariant' classNames and target them as overrides.
*/

import { checkboxClasses } from '@mui/material/Checkbox';

export const myComponents = {
    MuiFormControl: {
        styleOverrides: {
            root: {
                margin: '0'
            }
        }
    }
    , MuiContainer: {
        variants: [
            {
                props: { variant: 'contrast' },
                style: {
                    backgroundColor: 'white',
                    borderRadius: 'var(--default-radius)',
                    border: '1px solid var(--outline)',
                    padding: '16px 20px',
                    margin: '8px 0'

                }
            }
            , {
                props: { variant: 'contrastBig' },
                style: {
                    backgroundColor: 'white',
                    borderRadius: 'var(--default-radius)',
                    border: '1px solid var(--outline)',
                    padding: '24px',
                    margin: '8px 0'

                }
            }
        ]

    }
    , MuiTextField: {
        styleOverrides: {
            root: {
                width: '100%',
                /* margin: '8px 0', */
                ' & label': {
                    fontFamily: 'Nunito'
                    , fontWeight: 400
                    , fontSize: '20px'
                    , color: 'var(--secondary-text)'
                }
                , ' &.subvariant-small': {
                    ' & label': {
                        fontSize: '16px'
                    }
                    , ' & input': {
                        fontSize: '16px'
                    }
                }
            }
        }
    }
    , MuiAutocomplete: {
        styleOverrides: {
            root: {
                ' &.hiddenLabel': {
                    ' & label': {
                        display: 'none'
                    }
                }
            }
            , popper: {
                borderRadius: 'var(--default-radius)',
                boxShadow: 'var(--default-shadow)'
            }
            , paper: {
                border: '1px solid var(--outline)'
            }
            , listbox: {
                maxHeight: '175px'
            }
            /*             , option: {
                            ' &:nth-of-type(even) ': {
                                backgroundColor: 'black'
                            }
                        } */
            , option: {
                ' &.Mui-focused': {
                    backgroundColor: 'var(--hover) !important'
                }
            }
        }

    }
    , MuiInput: {
        styleOverrides: {
            root: {
                margin: '8px 0',
                fontFamily: 'Nunito'
                , fontWeight: 500
                , fontSize: '20px'
                , color: 'var(--primary-text)'
                , ' &.subvariant-small': {
                    fontSize: '16px'
                }
                , '&::before': {
                    borderBottom: '1px solid var(--secondary-text)'
                }
            }
        }
    }
    , MuiFormLabel: {
        styleOverrides: {
            root: {
                color: 'var(--secondary-text)'
                , '&.Mui-focused': {
                    color: 'var(--secondary-text)'
                }
            }
        }
    }
    , MuiLinearProgress: {
        styleOverrides: {
            colorPrimary: { backgroundColor: 'var(--secondary-color)' }
            , barColorPrimary: { backgroundColor: 'var(--primary-color-active)' }
        }
    }
    , MuiCheckbox: {
        styleOverrides: {
            root: {
                color: 'var(--outline)',
                [`&.${checkboxClasses.checked}`]: {
                    color: 'var(--primary-color-active)',
                },
                padding: '0 8px 0 0'
            }
        }
    }

    , MuiSlider: {
        styleOverrides: {
            root: {
                color: 'var(--primary-color-active)'
                , padding: '2px 0'
                , '@media (pointer: coarse)': {
                    padding: '10px 0'
                }
                , width: '100%'
            }
        }
        , thumb: {
            width: '12px'
            , height: '12px'
        }
    }
    , MuiButton: {
        variants: [
            {
                props: { variant: 'bado' },
                style: {
                    backgroundColor: 'white',
                    borderRadius: '100px',
                    padding: '8px 16px',
                    margin: '8px 0',
                    textTransform: 'none',
                    minWidth: '160px'

                }
            }
        ]
    }
}
