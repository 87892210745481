import React, { useState } from 'react'
import categoriesData from './categoriesData'
import './styles.css'
import ActionButton from '../../../../components/mix/ActionButton'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';



const ProductCategories = props => {
    const items = React.Children.toArray(categoriesData.map(categoryData => <Category categoryData={categoryData} />))
    const [activeIndex, setActiveIndex] = useState(0)

    return <section id='section-productCategories'>
        <Typography variant='categoryTitle'>Categorias</Typography>
        <AliceCarousel mouseTracking items={items} responsive={{0: {items: 1}, 1000: {items: 3, itemsFit: 'contain'}}}
        disableDotsControls controlsStrategy='responsive' activeIndex={activeIndex}
        />
    </section>
}

const Category = props => {
    const { src, name } = props.categoryData
    const nav = useNavigate()
    const handleDragStart = (e) => e.preventDefault();

    return <div className='productCategory' onDragStart={handleDragStart}>
        <img className='productCategory__img' src={src} role="presentation"/>
        <Typography variant='text' sx={{ textTransform: 'capitalize' }}>{name}</Typography>
        <ActionButton label='Ver produtos' onClick={() => nav('/categorias/' + name)}
            sx={{ backgroundColor: 'black', color: 'white' }} />
    </div>

}
export default ProductCategories