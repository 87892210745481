import './App.css';

// Router
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Pages
import Home from './pages/home/Home';
import Wrapper from './pages/wrapper/Wrapper'
import FAQ from './pages/faq/FAQ';
import NossaHistoria from './pages/nossaHistoria/NossaHistoria'
import Produtos from './pages/produtos/Produtos';

const App = () => {
  return <Router>
    <Wrapper>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nossa-historia" element={<NossaHistoria />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/produtos" element={<Produtos />} />
      </Routes>
    </Wrapper>
  </Router>
}

export default App;
