import Header from './sections/section_header/header';
import Sticky from './sections/sticky_cta/sticky';
import Footer from './sections/section_footer/footer';
import Copyright from './sections/section_copyright/copyright';

const Wrapper = (props) => {
    return <>
        <Header />
        <Sticky />
        {props.children}
        <Footer />
        <Copyright />
    </>
}

export default Wrapper