import './styles.css'

import center from './assets/center.jfif'
import right from './assets/right.jfif'

const Quote = () => {
    return <section id='section-quote'>
        <div id='quote__text'>
            <p id='quote__text--quote'>"Como cartas, escritas não com tinta..."</p>
            <p id='quote__text--reference'>2Cor 3:2</p>
        </div>
        <div className='quoteImgs__container'>
            <img id='quote__imgCenter' src={center} />
            <img id='quote__imgRight' src={right} />
        </div>

    </section>
}

export default Quote